var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { m, AnimatePresence } from 'framer-motion';
// @mui
import { alpha } from '@mui/material/styles';
import { IconButton, Stack, Typography } from '@mui/material';
// components
import Iconify from '../../iconify';
import { varFade } from '../../animate';
import FileThumbnail, { fileData } from '../../file-thumbnail';
// utils
import { fData } from '../../../utils/formatNumber';
// ----------------------------------------------------------------------
export default function MultiFilePreview(_a) {
    var thumbnail = _a.thumbnail, files = _a.files, onRemove = _a.onRemove, sx = _a.sx;
    if (!(files === null || files === void 0 ? void 0 : files.length)) {
        return null;
    }
    return (_jsx(AnimatePresence, __assign({ initial: false }, { children: files.map(function (file) {
            var _a = fileData(file), key = _a.key, _b = _a.name, name = _b === void 0 ? '' : _b, _c = _a.size, size = _c === void 0 ? 0 : _c;
            var isNotFormatFile = typeof file === 'string';
            if (thumbnail) {
                return (_jsxs(Stack, __assign({ component: m.div }, varFade().inUp, { alignItems: "center", display: "inline-flex", justifyContent: "center", sx: __assign({ m: 0.5, width: 80, height: 80, borderRadius: 1.25, overflow: 'hidden', position: 'relative', border: function (theme) { return "solid 1px ".concat(theme.palette.divider); } }, sx) }, { children: [_jsx(FileThumbnail, { tooltip: true, imageView: true, file: file, sx: { position: 'absolute' }, imgSx: { position: 'absolute' } }), onRemove && (_jsx(IconButton, __assign({ size: "small", onClick: function () { return onRemove(file); }, sx: {
                                top: 4,
                                right: 4,
                                p: '1px',
                                position: 'absolute',
                                color: function (theme) { return alpha(theme.palette.common.white, 0.72); },
                                bgcolor: function (theme) { return alpha(theme.palette.grey[900], 0.48); },
                                '&:hover': {
                                    bgcolor: function (theme) { return alpha(theme.palette.grey[900], 0.72); },
                                },
                            } }, { children: _jsx(Iconify, { icon: "eva:close-fill", width: 16 }) })))] }), key));
            }
            return (_jsxs(Stack, __assign({ component: m.div }, varFade().inUp, { spacing: 2, direction: "row", alignItems: "center", sx: __assign({ my: 1, px: 1, py: 0.75, borderRadius: 0.75, border: function (theme) { return "solid 1px ".concat(theme.palette.divider); } }, sx) }, { children: [_jsx(FileThumbnail, { file: file }), _jsxs(Stack, __assign({ flexGrow: 1, sx: { minWidth: 0 } }, { children: [_jsx(Typography, __assign({ variant: "subtitle2", noWrap: true }, { children: isNotFormatFile ? file : name })), _jsx(Typography, __assign({ variant: "caption", sx: { color: 'text.secondary' } }, { children: isNotFormatFile ? '' : fData(size) }))] })), onRemove && (_jsx(IconButton, __assign({ edge: "end", size: "small", onClick: function () { return onRemove(file); } }, { children: _jsx(Iconify, { icon: "eva:close-fill" }) })))] }), key));
        }) })));
}
